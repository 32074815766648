import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import './AboutPage.css';
import NavBar from '../Components/NavBar';

class AboutPage extends Component {
    constructor(props) {
        super();

        this.openUrl = this.openUrl.bind(this);
    }

    openUrl(url) {
        window.open(url, "_self");
    }

    render() {
      let tabData = [
          // {title: "Home", action: () => this.openUrl('/')}
      ];
      return (
        <div className="aboutPage">
          <Helmet>
            <title>End of Lease Carpet Cleaning Bendigo | About Us</title>
          </Helmet>
          <NavBar tabData={tabData} titleTop={"End of Lease"} titleBottom={"Carpet Cleaning Bendigo"} mainAction={() => this.openUrl('/')}/>
          <h1>About</h1>
          <div className="messageText">Express steam carpet cleaning from as little as $140.</div>
          <div className="messageText">End of lease specialist servicing Bendigo, Castlemaine & Huntly.</div>
          <div className="messageText">Reliable owner operator with 10 years experience.</div>
          <div className="messageText">Call now: 0419 337 466</div>
        </div>
      );
    }
}

export default AboutPage;