import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import './BookingUnavailablePage.css';
import NavBar from '../Components/NavBar';

class BookingUnavailablePage extends Component {
    constructor(props) {
        super();

        this.openUrl = this.openUrl.bind(this);
    }

    openUrl(url) {
        window.open(url, "_self");
    }

    render() {
      let tabData = [
          // {title: "Home", action: () => this.openUrl('/')}
      ];
      return (
        <div className="bookingUnavailablePage">
          <Helmet>
            <title>End of Lease Carpet Cleaning Bendigo | Temporarily Unavailable</title>
          </Helmet>
          <NavBar tabData={tabData} titleTop={"End of Lease"} titleBottom={"Carpet Cleaning Bendigo"} mainAction={() => this.openUrl('/')}/>
          <h1>Online Booking Temporarily Unavailable</h1>
          <div className="messageText">To arrange a cleaning or request a quote please call <b>0419 337 466</b>.</div>
          <div className="messageText">
            <ul className="requirementList">
              <p><b>Details Required:</b></p>
              <li>First Name</li> 
              <li>Surname</li>
              <li>Address</li>
              <li>Number of Rooms</li>
              <li>Date</li>
              <li>Time (9am, 11am or 1pm)</li>
              <p>Receipt Supplied.</p>
            </ul>
          </div>
        </div>
      );
    }
}

export default BookingUnavailablePage;